.images-product {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  margin: 20px 0;

  &.first {
    grid-template-columns: 1fr;
  }
}

// .product_edit_card_input_label {
//   position: relative;
//   overflow: hidden;
//   display: inline-block;
//   width: 100%;
//   height: 150px;
//   // background-image: url("../../assets/images/img.png");
//   background-repeat: no-repeat;
//   background-position: center;
//   background-size: contain;
//   border-radius: 10px;
//   margin-bottom: 16px;

//   input {
//     display: none;
//   }

//   .upload-icon {
//     padding: 105px 0;

//     svg {
//       display: inline-block;
//       width: 100%;
//       margin: 0 auto;
//     }

//     p {
//       color: #FFF;
//       text-align: center;
//       font-feature-settings: 'clig' off, 'liga' off;
//       font-family: Poppins;
//       font-size: 16px;
//       font-style: normal;
//       font-weight: 500;
//       line-height: 20px;
//       text-align: center;
//     }
//   }
// }
.listImages{
  display: flex;
align-items: center;
flex-wrap: wrap;
}

// YourImageUploader.scss

// .product_edit_card_input_label {
//   position: relative;
//   overflow: hidden;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   cursor: pointer;
//   width: 100%; // Adjust as needed
//   height: 200px; // Adjust as needed
//   border: 2px dashed #f2f2f2; // Adjust the color
//   border-radius: 5px;
//   transition: border-color 0.3s ease;

//   &:hover {
//     border-color: #f2f2f2; // Adjust the hover color
//   }

//   input {
//     position: absolute;
//     width: 100%;
//     height: 100%;
//     top: 0;
//     left: 0;
//     opacity: 0;
//     cursor: pointer;
//   }

//   .upload-icon {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     color: #3498db; // Adjust the color
//   }

//   svg {
//     width: 50px; // Adjust the size
//     height: 50px; // Adjust the size
//   }

//   p {
//     margin: 5px 0 0;
//     font-size: 14px; // Adjust the font size
//     color: #555; // Adjust the color
//   }
// }

// .images-product {
//   // display: flex;
//   // flex-wrap: wrap;
//   margin-top: 10px;

//   div {
//     width: calc(33.33% - 10px); // Adjust the width and margin as needed
//     margin: 5px;
//     overflow: hidden;
//     position: relative;

//     img {
//       width: 100%;
//       height: auto;
//       border-radius: 5px;
//       box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); // Add a subtle shadow
//     }
//   }
// }