.sidebar {
  padding: 24px;
  background: #363740;
  height: 110vh;
  .sidebar-top {
    margin-bottom: 378px;
    height: 45vh;
    .sidebar-logo {
      margin-bottom: 24px;
      a > img {
        display: block;
        margin: 0 auto;
      }
    }
    .menu {
      display: flex;
      flex-direction: column;
      .link {
        display: flex;
        align-items: center;
        gap: 9px;
        padding: 14px 15px;
        border-radius: 8px;
        transition: all ease 0.5s;
        color: #929eae;
        font-family: "Poppins";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        background: none;
        border: none;
        cursor: pointer;
        svg {
          path {
            transition: all ease 0.5s;
          }
        }
        &.active {
          background: linear-gradient(96deg, #1d9da0 -37.71%, #7378ee 137.71%);
          transition: all ease 0.5s;
          color: #fff;

          svg {
            path {
              stroke: #fff;
              transition: all ease 0.5s;
            }
          }
        }
      }
    }
  }
  .sidebar-bottom {
    a {
      display: flex;
      gap: 9px;
      padding: 14px 15px;
      border-radius: 8px;
      transition: all ease 0.5s;
      color: #929eae;
      font-family: "Poppins";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      svg {
        path {
          transition: all ease 0.5s;
        }
      }
      &:hover {
        background: linear-gradient(96deg, #1d9da0 -37.71%, #7378ee 137.71%);
        transition: all ease 0.5s;
        color: #fff;
        svg {
          path {
            stroke: #fff;
            transition: all ease 0.5s;
          }
        }
      }
    }
  }
}
