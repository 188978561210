.admin-about {
    h1 {
        color: #1B212D;
        font-family: 'Poppins';
        font-size: 25px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.5px;
        margin-bottom: 16px;
    }
    .about-cards{
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 30px;
        flex-wrap: wrap;
    }
}