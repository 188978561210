.imageStyle{
    width: 100%;
    object-fit: contain;
}
.product-card {

    &:hover {
        .card-img {
            .card-edit {
                opacity: 1;
                transition: all ease 0.5s;
            }
        }
    }

    .card-img {
        height: 240px;
        margin-bottom: 32px;
        position: relative;
        border-radius: 12.523px;
        background: #F6F7FA;
        object-fit: contain;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

        .card-edit {
            background: rgba(0, 0, 0, 0.20);
            -webkit-backdrop-filter: blur(4px);
            backdrop-filter: blur(4px);
            border-radius: 8px;
            padding: 102px 0;
            width: 100%;
            position: absolute;
            transition: all ease 0.5s;
            bottom: 0;
            display: flex;
            gap: 4px;
            opacity: 0;

            button {
                margin: 0 auto;
                text-align: center;
                align-items: center;
                padding: 8px 10px;
                border-radius: 4px;
                background: #FFF;
                display: flex;
                gap: 4px;
                color: #000;
                text-align: center;
                font-family: 'Inter';
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                height: 36px;
                border: none;
                cursor: pointer;
            }
        }
    }

    .card-text {
        h1 {
            color: #000;
            font-family: 'Inter';
            font-size: 15.654px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: -0.157px;
            margin-bottom: 6px;
        }


        p {
            overflow: hidden;
            color: #000;
            text-overflow: ellipsis;
            // white-space: nowrap;
            font-family: 'Inter';
            font-size: 12.523px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            letter-spacing: -0.125px;
            max-width: 228px;
            overflow: hidden;
            margin-top: 20px;
        }

    }

}

.product_edit_card {
    .product_edit_card_info {
        color: #000;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Poppins;
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: 30px;
letter-spacing: -1px;
margin-bottom: 16px;
    }
    
    .product_edit_card_img {
        width: 388px;
        height: 146px; 
        object-fit: cover;
        border-radius: 10px; 
    }

    .product_edit_card_input_label {
      position: relative;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 20px;
      height: 30px;
      margin-bottom: 16px;
      border: 1px solid #d9d9d9;
    }
    
    .product_edit_card_input {
      display: none;
    }

    .product_edit_card_title {
color: #111;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px;
margin-bottom: 6px;
    }

    .product_edit_card_edite {
        border-radius: 4px;
background: #F5F5F5;
height: 50px; 
padding: 14px 32px; 
color: #292929;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px;
letter-spacing: 0.5px;
    }

    .product_edit_card_save {
color: #FFF;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px;
letter-spacing: 0.5px;
border-radius: 4px;
background: #021E34;
height: 50px;
padding: 14px 32px; 
    }
}
.ant-modal {
    .ant-modal-content {
        width: 448px;

        padding: 30px;

        .ant-modal-header {
            .ant-modal-title {
                color: #000;
                font-feature-settings: 'clig'off, 'liga'off;
                font-family: 'Poppins';
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: 30px;
                /* 125% */
                letter-spacing: -1px;
            }
        }

        .ant-modal-body {
            p {
                margin-bottom: 20px;
            }

        }

        .ant-modal-footer {
            display: none;

        }

        .modal-footer {
            display: flex;
            gap: 10px;

            button {
                // padding: 14px 32px;
                width: 160px;
                height: 50px;
                border-radius: 4px;
                background: #F5F5F5;
                text-align: center;
                justify-content: center;
                margin: 0 auto;
                border: none;
                cursor: pointer;

                &:last-child {
                    background: #000;

                    span {
                        color: #FFF;
                    }
                }

                span {
                    display: flex;
                    text-align: center;
                    color: #292929;
                    font-feature-settings: 'clig'off, 'liga'off;
                    font-family: 'Poppins';
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                    letter-spacing: 0.5px;
                    width: 100%;
                    justify-content: center;

                }
            }

            .delete-btn {
                // padding: 14px 32px;
                width: 160px;
                height: 50px;
                border-radius: 4px;
                background: #F5F5F5;
                text-align: center;
                justify-content: center;
                margin: 0 auto;
                border: none;
                cursor: pointer;

                &:last-child {
                    background: #EA0000;
                    ;

                    span {
                        color: #FFF;
                    }
                }

                span {
                    display: flex;
                    text-align: center;
                    color: #292929;
                    font-feature-settings: 'clig'off, 'liga'off;
                    font-family: 'Poppins';
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                    letter-spacing: 0.5px;
                    width: 100%;
                    justify-content: center;

                }
            }
        }
    }
}