.login {
    padding: 158px 0 0 0;

    .login-input {
        width: 396px;
        margin: 0 auto;

        .logo {
            display: flex;
            margin: 0 auto 24px auto;

            a {
                display: flex;
                margin: 0 auto;
            }
        }

        .login-title {
            text-align: center;
            margin: 0 auto 32px auto;

            h1 {
                color: #101828;
                text-align: center;
                font-family: 'Inter';
                font-size: 30px;
                font-style: normal;
                font-weight: 600;
                line-height: 38px;
                margin: 0 auto;
                margin-bottom: 8px;
            }

            p {
                color: #667085;
                text-align: center;
                font-family: 'Inter';
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
            }
        }

        .login-input-body {
            .ant-form {
                .ant-form-item {
                    // margin-bottom: 8px;
                    height: 70px;

                    &:nth-child(3) {
                        height: 40px;

                        .ant-form-item-control-input-content {
                            display: flex;
                            justify-content: space-between;
                        }

                        color: #344054;
                        font-family: 'Inter';
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 20px;

                        a {
                            color: #021E34;
                            font-family: 'Inter';
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 20px;
                        }
                    }

                    .ant-row {
                        flex-direction: column;

                        .ant-col.ant-form-item-label {
                            text-align: left;

                            label {
                                text-align: left;
                                color: #344054;
                                font-family: 'Inter';
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: 12px;

                                &:before {
                                    display: none;
                                }
                            }
                        }

                        .ant-col.ant-form-item-control {
                            .ant-form-item-control-input-content {
                                input {
                                    border-radius: 8px;
                                    border: 1px solid #D0D5DD;
                                    background: transparent;
                                    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                                }

                                .ant-input-affix-wrapper.ant-input-password {
                                    border-radius: 8px;
                                    border: 1px solid #D0D5DD;
                                    background: transparent;
                                    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

                                    input {
                                        border: none;
                                        box-shadow: none;
                                    }
                                }

                            }

                        }
                    }
                }

            }
        }
    }
}
.login-btn {
    
    padding: 10px 174px;
    border-radius: 8px;
    border: 1px solid #6C0000;
    background: #6C0000;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    color: #FFF;
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
}