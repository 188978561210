.counter-card {
    display: flex;
    width: 216px;
    padding: 20px 24px;
    align-items: center;
    gap: 10px;
    border-radius: 8.326px;
    background: #F8F8F8;

    .counter-card-left {
        border-radius: 8.326px;
        background: #ECF0FB;
        // padding: 9px;
        width: 40px;
        height: 40px;

        img {
            padding: 10px;
        }
    }

    .card-right {
        p {
            color: #000;
            font-family: 'Poppins';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            margin-bottom: 5px;
        }

        h1 {
            color: #000;
            font-family: 'Poppins';
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 30px;
            margin-bottom: 0;
        }
    }
}