.news-page {
    padding: 30px 0;

    select {}

    .page-top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;

        h1 {
            color: #000;
            font-family: 'Poppins';
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.4px;

        }

        button {
            color: #021E34;
            text-align: right;
            font-family: 'Poppins';
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            text-decoration-line: underline;
            background: none;
            border: none;
            cursor: pointer;
        }
    }

    .page-cards {
        // display: flex;
        align-content: center;
        // justify-content: space-between;
        flex-wrap: wrap;
        display: grid;
        grid-template-columns: repeat(4,1fr);
        gap: 72px;
        margin-bottom: 30px;

        &::-webkit-scrollbar {
            display: none;
        }
    }
}

.categorycard_edit_input_label {
  position: relative;
  overflow: hidden;
  display: inline-block;
  width: 100%;
  height: 150px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-bottom: 16px;

  input {
    display: none;
  }

  .upload-icon {
    padding: 48px 0;
 
    svg {
        display: inline-block;
        width: 100%;
        margin: 0 auto;
    }

 p {
color: #FFF;
text-align: center;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 20px;
text-align: center;
 }
}
}

.ant-modal {
    .ant-modal-content {
        width: 448px;

        padding: 30px;

        .ant-modal-header {
            .ant-modal-title {
                color: #000;
                font-feature-settings: 'clig'off, 'liga'off;
                font-family: 'Poppins';
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: 30px;
                /* 125% */
                letter-spacing: -1px;
            }
        }

        .ant-modal-body {
            .modal-body {
                .body-img {
                    form {
                        display: flex;
                        flex-direction: column;

                        .ant-upload {
                            width: 100%;
                            border-radius: 8px;
                            // border: 1px solid #D3D3D3;
                            height: 146px;
                            margin-bottom: 12px;
                        }

                        label {
                            color: #111;
                            font-feature-settings: 'clig'off, 'liga'off;
                            font-family: 'Poppins';
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 20px;
                            margin-bottom: 6px;
                            color: #111;
                            font-feature-settings: 'clig'off, 'liga'off;
                            font-family: 'Poppins';
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 20px;
                            /* 142.857% */
                            /* 142.857% */
                        }

                        input {
                            padding: 10px 16px;
                            width:100%;
                            border-radius: 4px;
                            border: 1px solid #B8B8B8;
                            background: #FFF;
                            outline: unset;
                            margin-bottom: 12px;
                        }

                        select {
                            padding: 10px 16px;
                            width: 100%;
                            border-radius: 4px;
                            border: 1px solid #B8B8B8;
                            background: #FFF;
                            outline: unset;
                            margin-bottom: 12px;
                        }
                    }
                }
            }

        }

        .ant-modal-footer {
            display: none;

        }

        .modal-footer {
            display: flex;
            gap: 10px;

            button {
                // padding: 14px 32px;
                width: 160px;
                height: 50px;
                border-radius: 4px;
                background: #F5F5F5;
                text-align: center;
                justify-content: center;
                margin: 0 auto;
                border: none;
                cursor: pointer;

                &:last-child {
                    background: #000;

                    span {
                        color: #FFF;
                    }
                }

                span {
                    display: flex;
                    text-align: center;
                    color: #292929;
                    font-feature-settings: 'clig'off, 'liga'off;
                    font-family: 'Poppins';
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                    letter-spacing: 0.5px;
                    width: 100%;
                    justify-content: center;

                }
            }
        }
    }
}

.image-cover {
    &:hover {
        img {
            transform: scale(1.5);
            transition: all ease 0.5s;
        }
    }

    input {
        display: none;
    }

    border: 1px dashed #d3d3d3;
    border-radius: 8px;
    padding: 60px 0;
    margin-bottom: 12px;
    display: block;
    width: 100%;

    img {
        display: block;
        transition: all ease 0.5s;
        cursor: pointer;
        margin: 0 auto;
    }

    p {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 30px;
        text-align: center;
        letter-spacing: -0.01em;
        color: rgba(0, 0, 0, 0.5);
    }

    //   height: 150px;
}