.bannercard {
    max-width: 1000px;
    width: 100%;
    height: 400px;

    &:hover {
        .card-img {
            .card-edit {
                display: block;
                opacity: 1;
                transition: opacity ease 0.5s;
            }
        }
    }

    .card-img {
        width: 100%;
        height: 400px;
        margin-bottom: 32px;
        position: relative;
        object-fit: cover;
        border-radius: 12px;
        background: #F6F7FA;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 12px; 
            transition: transform 0.3s ease; 
        }

        &:hover img {
            transform: scale(1.05);
        }

        .card-edit {
            background: rgba(0, 0, 0, 0.20);
            -webkit-backdrop-filter: blur(4px);
            backdrop-filter: blur(4px);
            border-radius: 8px;
            width: 100%;
            height: 500px;
            
            position: absolute;
            transition: all ease 0.5s;
            top: 0;
            bottom: 0;
            display: flex;
            gap: 4px;
            opacity: 0;

            button {
                position: absolute;
                top: 16px;
                right: 16px;
                margin: 0 auto;
                display: inline-flex;
                padding: 8px 10px;
                justify-content: center;
                align-items: center;
                gap: 4px;
                text-align: center;
                border-radius: 4px;
                background: #FFF;
                color: #000;
                font-family: 'Inter', sans-serif;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                height: 30px;
                cursor: pointer;
                border: none;
            }

            .card_edit {
                right: 120px;
            }
        }
    }

    .card-text {
        padding: 16px;

        h1 {
            color: #000;
            font-family: 'Inter', sans-serif;
            font-size: 18px;
            font-weight: 700;
            line-height: 1.2;
            margin-bottom: 12px;
        }

        p {
            color: #000;
            font-family: 'Inter', sans-serif;
            font-size: 14px;
            font-weight: 400;
            line-height: 140%;
            letter-spacing: -0.125px;
            max-width: 228px;
            overflow: hidden;
            margin-top: 20px;

        }
    }
}

.bannercard_modal {
    .bannercard_modal_info {
        color: #000;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Poppins;
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: 30px;
letter-spacing: -1px;
margin-bottom: 16px;
    }
    
    .bannercard_modal_img {
        width: 388px;
        height: 146px; 
        object-fit: cover;
        border-radius: 10px; 
    }

    .bannercard_modal_input_label {
      position: relative;
      overflow: hidden;
      display: inline-block;
      width: 100%;
      height: 150px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      margin-bottom: 16px;
      border-radius: 10px; 
    
      .upload-icon {
        padding: 105px 0;
     
        svg {
            display: inline-block;
            width: 100%;
            margin: 0 auto;
        }
    
     p {
    color: #FFF;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
     }
    }
    }
    
    .bannercard_modal_input {
      display: none;
    }

    .bannercard_modal_title {
color: #111;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px;
margin-bottom: 6px;
    }

    .bannercard_modal_edite {
        border-radius: 4px;
background: #F5F5F5;
height: 50px; 
padding: 14px 32px; 
color: #292929;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px;
letter-spacing: 0.5px;
    }

    .bannercard_modal_save {
color: #FFF;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px;
letter-spacing: 0.5px;
border-radius: 4px;
background: #021E34;
height: 50px;
padding: 14px 32px; 
    }
}

.ant-modal {
    .ant-modal-content {
        background-color: #ffffff !important;
        width: 448px;

        padding: 30px;

        .ant-modal-header {
            .ant-modal-title {
                display: none;
            }
        }

        .ant-modal-body {
            p {
                margin-bottom: 20px;
                color: black;
            }

        }

        .ant-modal-footer {
            display: none;

        }

        .modal-footer {
            display: flex;
            gap: 10px;

            button {
                padding: 14px 32px;
                width: 160px;
                height: 50px;
                border-radius: 4px;
                background: #F5F5F5;
                text-align: center;
                justify-content: center;
                margin: 0 auto;
                border: none;
                cursor: pointer;

                &:last-child {
                    background: #000;

                    span {
                        color: #FFF;
                    }
                }

                span {
                    display: flex;
                    text-align: center;
                    color: #292929;
                    font-feature-settings: 'clig'off, 'liga'off;
                    font-family: 'Poppins';
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                    letter-spacing: 0.5px;
                    width: 100%;
                    justify-content: center;

                }
            }

            .delete-btn {
                // padding: 14px 32px;
                width: 160px;
                height: 50px;
                border-radius: 4px;
                background: #F5F5F5;
                text-align: center;
                justify-content: center;
                margin: 0 auto;
                border: none;
                cursor: pointer;

                &:last-child {
                    background:  #EA0000;;

                    span {
                        color: #FFF;
                    }
                }

                span {
                    display: flex;
                    text-align: center;
                    color: #292929;
                    font-feature-settings: 'clig'off, 'liga'off;
                    font-family: 'Poppins';
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                    letter-spacing: 0.5px;
                    width: 100%;
                    justify-content: center;

                }
            }
        }
    }
}
