  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;400;500;600;700;800;900&family=Poppins:wght@200;300;400;500;600;700&display=swap');

  body {
      padding: 0;
      margin: 0;
      font-family: 'Inter', sans-serif;
      font-family: 'Poppins', sans-serif;
      box-sizing: border-box;
      &::-webkit-scrollbar {
          display: none;
      }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  a {
      padding: 0;
      margin: 0;
      text-decoration: none;
      // font-family: "HelveticaNeueCyr-Black";
  }

  .container-xxl {
      width: 100%;
      margin: 0 auto;
      max-width: 1920px;
      padding: 0;
  }

  label {
      width: 100%;
  }

  .container-xl {
      width: 100%;
      max-width: 1401px;
      margin: 0 auto;
      padding: 0;
  }

  .container {
      width: 100%;
      max-width: 1320px;
      margin: 0 auto;
      padding: 0;
  }

  .ant-pagination{
    display: flex !important;
    justify-content: center !important;
  }

  .ant-upload{
    width: 100% !important;
  }
  .ant-upload{
    button{
        width: 100% !important;
        height: 100px !important;
    }
  }